exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-docs-privacy-tsx": () => import("./../../../src/pages/docs/privacy.tsx" /* webpackChunkName: "component---src-pages-docs-privacy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */)
}

